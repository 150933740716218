import {
  IonBackButton,
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonModal,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  useIonActionSheet,
  useIonViewDidEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import styles from "./quiz.module.scss";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gameActions } from "../../_redux/actions/game.actions";
import { partidaActions } from "../../_redux/actions/partida.actions";
import {
  DataGame,
  GameState,
  RankingOnlineRequest,
} from "../../_redux/models/game.model";
import { history } from "../../_helpers/history";
import { questionTypesEnum } from "../../_redux/types/question.enum";
import { isPlatform } from "@ionic/react";
import {
  play,
} from "ionicons/icons";
import { useTranslation } from "react-i18next";
import useState from "react-usestateref";
import {
  delay,
  GuardarPartida,
  OperacionSaveR,
  RandomIntFromInterval,
  deshabilitarBoton,
  shareHander,
  OrdenarLoja,
} from "../../utils/functions";
import { PartidaState } from "../../_redux/models/partida.model";
import { useSound } from "use-sound";
import CorrectoLottie from "../../components/Lotties/CorrectoLottie";
import ErrorLottie from "../../components/Lotties/ErrorLottie";
import NivelLottie from "../../components/Lotties/NivelLottie";
import { AuthState } from "../../_redux/models/auth.model";
import { gameService } from "../../_redux/services";
import { Producto } from "../../_redux/models/producto.model";
import { appActions } from "../../_redux/actions/app.actions";
import { MenuApp, VerificarSiTocaLogro } from "../../utils/functions";
import { Capacitor } from "@capacitor/core";
import { AppStore, AppState } from "../../_redux/models/app.model";
import Corazon from "../../components/Lotties/Corazon";

import {
  LogroState,
  LogrosUsuario,
} from "../../_redux/models/logro.models";
import Timer from "../../components/Timer/Timer";
import BannerLocalLateralGrande from "../../components/Ads/BannerLocalLateralGrande";
import GanaLogro from "../../components/Logro/GanaLogro";
import { TypeQuestion } from "../../_redux/models/game.model";
import {
  AdLoadInfo,
  AdMob,
  AdOptions,
  InterstitialAdPluginEvents,
} from "@capacitor-community/admob";
import Media from "react-media";
import ReactHtmlParser from "react-html-parser";
const Quiz: React.FC<any> = (props) => {

  const dispatch = useDispatch();
  const timerRef = useRef(null);
  const partida: PartidaState = useSelector((state: any) => state.partida);
  const refValuePartida = useRef(partida);
  const game: GameState = useSelector((state: any) => state.game);
  const refValueGame = useRef(game);
  const authUser: AuthState = useSelector((state: any) => state.auth);
  const refValueAuthUser = useRef(authUser);
  const appConfig: AppState = useSelector((store: AppStore) => store.app);
  const logros: LogroState = useSelector((state: any) => state.logro.logro);
  const [showModal, setShowModal] = useState(false);
  const [logroModal, setLogroModal] = useState(false);
  const [miLogro, setMiLogro] = useState<LogrosUsuario>(null);

  const [ayudaBoton50, setAyudaBoton50] = useState(false);
  const [ayudaBotonMasTiempo, setAyudaBotonMasTiempo] = useState(false);
  const [ayudaBotonPreguntaCorrecta, setAyudaBotonPreguntaCorrecta] =
    useState(false);
  const [ayudaBotonConservarPuntos, setAyudaBotonConservarPuntos] =
    useState(false);
  const [ayudaBotonContestar2Veces, setAyudaBotonContestar2Veces] =
    useState(false);

  const [respuesta1, setrespuesta1] = useState(false);
  const [respuesta2, setrespuesta2] = useState(false);
  const [respuesta3, setrespuesta3] = useState(false);
  const [respuesta4, setrespuesta4] = useState(false);

  const [posicion, setPosicion] = useState<number>(0);

  const [activaAyuda2Oportunidades, setActivaAyuda2Oportunidades] =
    useState(false);
  const [
    activaAyuda2NoPerderPuntosSiFalla,
    setactivaAyuda2NoPerderPuntosSiFalla,
  ] = useState(false);

  const [screengame, setScreengame] = useState(true);
  const [screengameBien, setScreengameBien] = useState(false);
  const [screengameMal, setScreengameMal] = useState(false);
  const [screengameNivel, setScreengameNivel] = useState(false);
  const [screengameSemVida, setScreengameSemVida] = useState(false);
  const [respuestaCorrecta, setRespuestaCorrecta] = useState("");
  const [respuestaIncorrecta, setRespuestaIncorrecta] = useState("");
  const [sonidoCorrecto] = useSound("/assets/sound/correcto.mp3", {
    volume: 1,
  });
  const [sonidoError] = useSound("/assets/sound/error.mp3", { volume: 1 });
  const [sonidoAyuda] = useSound("/assets/sound/tienda.mp3", { volume: 1 });
  const [sonidoNivel] = useSound("/assets/sound/cohete.mp3", { volume: 1 });
  const [sonidoGameOver] = useSound("/assets/sound/gameOver.mp3", {
    volume: 1,
  });
  //const [num, setNum, numRef] = useState(1);
  //const [pause, setPause] = useState(false);

  const [actualizarACada, setActualizarACada] = useState(50);
  const { t } = useTranslation();

  let ayudaAuxControlTiempo = false;
  let auxComponentePregunta = questionTypesEnum.Photo;

  const [present, dismiss] = useIonActionSheet();
  let dataRanking: RankingOnlineRequest = {
    MiAppId: 0,
    TipoRanking: 0,
    Finalizado: true,
  };

  const gestionarGuardarPartida = async (
    partida: PartidaState,
    eljuego: GameState,
    finalizar: boolean
  ) => {
    try {
      dispatch(gameActions.IsBusy());
      dispatch(gameActions.PARAR_JUEGO());
      partida.numberOfQuestions = eljuego.numberOfQuestions;
      await posicionRanking(partida);
      partida.finalizarJuego = finalizar;
      if (authUser.isAuth && finalizar) {
        //Si esta logueado y finaliza el juego
        dataRanking.Finalizado = true;
        partida.finalizarJuego = true;
        dataRanking.Punto = parseInt(partida.point.toFixed(0));
        dataRanking = OperacionSaveR(dataRanking, partida, authUser);
        const guardarRanking = await gameService.SetRanking(dataRanking);
        partida.id = guardarRanking;
        sonidoGameOver();
        history.replace("gameOver");
      } else if (finalizar) {
        partida.finalizarJuego = true;
        GuardarPartida(partida);
        //el usuario no esta logado, tenemos que guardar la partida en la modal si el mismo crea la cuenta
        sonidoGameOver();
        history.replace("gameOver");
      } else if (authUser.isAuth && !finalizar) {
        //Guardamos la partida para luego, solo si el usuario está autentificado
        dataRanking.Finalizado = false;
        partida.finalizarJuego = false;
        dataRanking.Punto = parseInt(partida.point.toFixed(0));
        dataRanking = OperacionSaveR(dataRanking, partida, authUser);
        const guardarRanking = await gameService.SetRanking(dataRanking);
        partida.id = guardarRanking;
        GuardarPartida(partida);
        history.replace("dashboard");
      } else {
        //Guardamos la partida de invitado para luego
        partida.finalizarJuego = false;
        GuardarPartida(partida);
        history.replace("dashboard");
      }
      dispatch(gameActions.QuitarIsBusy());
    } catch (error) {
      dispatch(gameActions.QuitarIsBusy());
    }
  };

  const HabitarBotones50Porcento = () => {
    setrespuesta1(false);
    setrespuesta2(false);
    setrespuesta3(false);
    setrespuesta4(false);
  };
  let datosGuardados: boolean = false;
  let intervalRef = useRef<number>();

  const masTiempoHander = () => {
    try {
      authUser.user.DatosExtendido.AyudaMasTiempo--;
      setAyudaBotonMasTiempo(false);
      ayudaAuxControlTiempo = false;
      sonidoAyuda();
      //setNum(1); código anterior
      handleReniciarTemporizador();
      if (authUser.user.Auth) {
        gameService.QuitarAyuda(Producto.MAS_TIEMPO);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const posicionRanking = async (partida: PartidaState) => {
    try {
      dataRanking.Punto = parseInt(partida.point.toFixed(0));
      const positicionActual = await gameService.PosicionRanking(dataRanking);
      if (positicionActual != null) {
        setPosicion(positicionActual);
        dispatch(partidaActions.actualizar_posicion(positicionActual));
      }
    } catch (error) {}
  };

  const cerrarModaGanalLogro = () => {
    setLogroModal(false);
  };

  const ayuda50Hander = async (partida: PartidaState) => {
    authUser.user.DatosExtendido.Ayuda50--;
    setAyudaBoton50(false);
    let arrayQuitadas = [];
    let indexRandom = RandomIntFromInterval(0, 3);
    do {
      if (!arrayQuitadas.includes(indexRandom)) {
        if (
          partida.tipo === questionTypesEnum.Photo &&
          partida.quiz[indexRandom].N !== partida.response.F
        ) {
          arrayQuitadas.push(indexRandom);
        } else if (
          partida.tipo === questionTypesEnum.Classic &&
          partida.quiz[indexRandom].N !== partida.response.N
        ) {
          arrayQuitadas.push(indexRandom);
        } else if (
          partida.tipo === questionTypesEnum.GPT &&
          partida.quiz[indexRandom].N !== partida.response.N
        ) {
          arrayQuitadas.push(indexRandom);
        }
      }
      indexRandom = RandomIntFromInterval(0, 3);
    } while (arrayQuitadas.length < 2);

    for (let i = 0; i < arrayQuitadas.length; i++) {
      if (arrayQuitadas[i] === 0) {
        setrespuesta1(true);
      } else if (arrayQuitadas[i] === 1) {
        setrespuesta2(true);
      } else if (arrayQuitadas[i] === 2) {
        setrespuesta3(true);
      } else if (arrayQuitadas[i] === 3) {
        setrespuesta4(true);
      }
    }

    sonidoAyuda();
    if (authUser.user.Auth) {
      gameService.QuitarAyuda(Producto.AYUDA_50);
    }
  };

  const handleStopTemporizador = () => {
    if (timerRef.current) {
      timerRef.current.handleStop();
    }
  };

  const handleReniciarTemporizador = () => {
    if (timerRef.current) {
      timerRef.current.handleStart();
    }
  };

  const handleQuitarTempoPorAquiWikipedia = () => {
    if (timerRef.current) {
      timerRef.current.handleQuitarAyuda();
    }
  };

  const retornarValorTemporizador = (): number => {
    try {
      if (timerRef.current) {
        return timerRef.current.count;
      } else {
        return 0;
      }
    } catch (error) {
      return 0;
    }
  };

  const ayudaRespuestaCorrectaHander = async () => {
    try {
      authUser.user.DatosExtendido.AyudaPreguntaCorrecta--;
      if (partida.tipo === questionTypesEnum.Photo) {
        setRespuestaCorrecta(partida?.response?.F);
      } else {
        setRespuestaCorrecta(partida?.response?.N);
      }

      sonidoAyuda();
      setAyudaBotonPreguntaCorrecta(false);

      GuardarPartida(partida);
      dispatch(
        partidaActions.aciertoQuestion(
          retornarValorTemporizador(),
          partida.point,
          partida.aciertos,
          partida.tiempoControl,
          100 - retornarValorTemporizador()
        )
      );
      sonidoCorrecto();
      setScreengameBien(true);
      setScreengame(false);

      if (partida.currentQuestion >= game.numberOfQuestions) {
        gestionarGuardarPartida(partida, game, true);
        return;
      }
      //Cambio de nivel
      else if (verificarCambioNivel(partida, true)) {
        CambioDeNivel(
          posicionRanking,
          partida,
          dispatch,
          setScreengameBien,
          setScreengameMal,
          setScreengame,
          setScreengameNivel,
          ganoLogro,
          setLogroModal
        );
        setScreengameBien(false);
        setScreengameNivel(true);
      } else {
        //Resto de casos al responder
        if (ganoLogro(partida)) {
          setLogroModal(true);
        }

        dispatch(
          partidaActions.proximaPregunta(
            partida.answered,
            partida.currentQuestion,
            game.cities,
            game.provinceCities
          )
        );

        await delay(2000);
        setScreengameBien(false);
        setScreengameNivel(false);
        setScreengameMal(false);
        setScreengame(true);
        handleReniciarTemporizador();
        actualizarAyudas();
        //initTime(decreaseNum);
      }
      if (authUser.user.Auth) {
        gameService.QuitarAyuda(Producto.Ayuda_Pregunta_Correcta);
      }
    } catch (error) {}
  };

  const ayudaBotonContestar2VeceHander = () => {
    try {
      authUser.user.DatosExtendido.AyudaContestar2Veces--;
      setActivaAyuda2Oportunidades(true);
      sonidoAyuda();
      setAyudaBotonContestar2Veces(false);
      if (authUser.user.Auth) {
        gameService.QuitarAyuda(Producto.Ayuda_Contestar_2Veces);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const ayudaConservarPuntosHander = async () => {
    authUser.user.DatosExtendido.RecuperarPuntosPerdido--;
    setactivaAyuda2NoPerderPuntosSiFalla(true);
    sonidoAyuda();
    setAyudaBotonConservarPuntos(false);

    if (authUser.user.Auth) {
      gameService.QuitarAyuda(Producto.Recuperar_Puntos_Perdido);
    }
    await delay(4000);
    dispatch(
      appActions.showToast({
        message: t("help_not_to_lose_points_activated", {}),
        type: "warning",
        position: "top",
        icon: "globe",
      })
    );
  };

  const verificarCambioNivel = (partida: PartidaState, isCorrect: boolean) => {
    if (partida.aciertos > 4 && partida.aciertos % 5 === 0 && isCorrect) {
      return true;
    }
    return false;
  };

  //Cambio de nivel - llamar cuando se aplica
  const CambioDeNivel = async (
    posicionRanking: (partida: PartidaState) => Promise<void>,
    partida: PartidaState,
    dispatch,
    setScreengameBien,
    setScreengameMal,
    setScreengame,
    setScreengameNivel,
    ganoLogro: (partida: PartidaState) => boolean,
    setLogroModal
  ) => {
    dispatch(partidaActions.cambio_nivel(partida.level));
    posicionRanking(partida);
    handleStopTemporizador();
    if (Capacitor.isNativePlatform()) {
      if (appConfig.isPrepareBanner) {
        //Bannerinterstitial();
        AdMob.addListener(
          InterstitialAdPluginEvents.Loaded,
          (info: AdLoadInfo) => {
            // Subscribe prepared interstitial
          }
        );
        AdMob.addListener(InterstitialAdPluginEvents.FailedToLoad, () => {
          // Subscribe prepared interstitial
          dispatch(appActions.bannerLocalModalMostrar());
        });

        const options: AdOptions = {
          adId: isPlatform("android")
            ? "ca-app-pub-8932590852481744/4996819698"
            : "ca-app-pub-8932590852481744/5926757985",
        };
        await AdMob.prepareInterstitial(options);
        await AdMob.showInterstitial();
      } else {
        dispatch(appActions.bannerLocalModalMostrar());
      }
    } else {
      dispatch(appActions.bannerLocalModalMostrar());
    }
    

    setScreengameBien(false);
    setScreengameMal(false);
    setScreengame(false);
    setScreengameNivel(true);
    if (ganoLogro(partida)) {
      setLogroModal(true);
    }
    sonidoNivel();
  };

  useEffect(() => {
    //https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component
    try {
      if (
        partida == null ||
        partida?.quiz == null ||
        partida?.currentQuestion === 0
      ) {
        if (
          authUser?.user?.DatosExtendido !== null &&
          authUser.user.DatosExtendido.Vida === 0
        ) {
          authUser.user.DatosExtendido.Vida += 1;
        }
        partida.gameStart = true; 
      }
      actualizarAyudas();
      //setNum(1);
      //initTime(decreaseNum);
    } catch (error) {}
  }, [authUser.user.DatosExtendido, game, partida]);

  const actualizarAyudas = () => {
    try {
      if (
        authUser != null &&
        //authUser.isAuth &&
        authUser.user.DatosExtendido != null
      ) {
        if (authUser.user.DatosExtendido.Ayuda50 > 0) {
          setAyudaBoton50(true);
        } else {
          setAyudaBoton50(false);
        }
        if (authUser.user.DatosExtendido.RecuperarPuntosPerdido > 0) {
          setAyudaBotonConservarPuntos(true);
        } else {
          setAyudaBotonConservarPuntos(false);
        }
        if (authUser.user.DatosExtendido.AyudaContestar2Veces > 0) {
          setAyudaBotonContestar2Veces(true);
        } else {
          setAyudaBotonContestar2Veces(false);
        }
        if (authUser.user.DatosExtendido.AyudaMasTiempo > 0) {
          setAyudaBotonMasTiempo(true);
          ayudaAuxControlTiempo = true;
        } else {
          setAyudaBotonMasTiempo(false);
          ayudaAuxControlTiempo = false;
        }
        if (authUser.user.DatosExtendido.AyudaPreguntaCorrecta > 0) {
          setAyudaBotonPreguntaCorrecta(true);
        } else {
          setAyudaBotonPreguntaCorrecta(false);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (partida.tipo === questionTypesEnum.GPT) {
      setActualizarACada(100);
    } else {
      setActualizarACada(50);
    }
  }, [partida]);

  useEffect(() => {
    actualizarAyudas();
  }, [authUser]);

  useIonViewWillLeave(async () => {
    try {
          timerRef?.current?.handleStop();
    dispatch(gameActions.PARAR_JUEGO());
    handleStopTemporizador();
    clearInterval(intervalRef.current);
    if (
      !datosGuardados &&
      refValueAuthUser?.current?.user?.DatosExtendido?.Vida <= 0
    ) {
      datosGuardados = true;
      gestionarGuardarPartida(
        refValuePartida.current,
        refValueGame.current,
        true
      );
    }
    if (
      refValueAuthUser === null ||
      refValueAuthUser === undefined ||
      refValueAuthUser.current === null ||
      !refValueAuthUser.current.isAuth
    ) {
      return;
    }
    if (refValueAuthUser.current.isAuth) {
      let userTemp = refValueAuthUser.current.user;
      userTemp.aspj = OrdenarLoja(refValueAuthUser.current.user);
      await gameService.UsuarioSalioDeLaPantalla(userTemp);
    }
    } catch (error) {
      console.log("error en useIonViewWillLeave",error);
    }

  });

 



  const cambioNivelContinuar = () => {
    setScreengameNivel(false);
    setScreengame(true);
    setScreengameBien(false);
    setScreengameSemVida(false);
    dispatch(
      partidaActions.proximaPregunta(
        partida.answered,
        partida.currentQuestion,
        game.cities,
        game.provinceCities
      )
    );
    handleReniciarTemporizador();
    HabitarBotones50Porcento();
  };

  const ganoLogro = (partida: PartidaState) => {
    return false;
  };

  function ClassicBox() {
    return <h1 className={styles.textoPregunta}>{t("question_game")}</h1>;
  }

  function GptBox() {
    return (
      <IonCard>
        <IonCardContent className="scroll_gptbox">
          {ReactHtmlParser(partida.response?.Z)}
        </IonCardContent>
      </IonCard>
    );
  }

  function PhotoBox() {
    return (
      <div className={styles.box}>
        <div className={styles.card}>
          <img
            className={styles.card_img_top + "skeleton"}
            src={partida.response?.N}
            alt={partida.response?.A}
          />
          <div className={styles.card_autor}>
            {t("photography_of", { response: partida.response?.A })}
          </div>
          <div className={styles.card_body}>{partida.response?.D}</div>
        </div>
      </div>
    );
  }

  function ComponentePregunta() {
    if (partida.tipo === questionTypesEnum.Photo) {
      return <PhotoBox />;
    } else if (partida.tipo === questionTypesEnum.GPT) {
      return <GptBox />;
    }
    return <ClassicBox />;
  }

  const continuarDespuesDelLogro = async () => {
    setLogroModal(false);

    //Si estamos en la última pregunta, finalizar juego
    if (partida.currentQuestion >= game.numberOfQuestions) {
      gestionarGuardarPartida(partida, game, true);
      return;
    }
    //Cambio de nivel - hay otro sitio igual, refactorizar
    else if (verificarCambioNivel(partida, true)) {
      CambioDeNivel(
        posicionRanking,
        partida,
        dispatch,
        setScreengameBien,
        setScreengameMal,
        setScreengame,
        setScreengameNivel,
        ganoLogro,
        setLogroModal
      );
    } else {
      //Resto de casos al responder
      if (true) {
        sonidoCorrecto();
      }

      dispatch(
        partidaActions.proximaPregunta(
          partida.answered,
          partida.currentQuestion,
          game.cities,
          game.provinceCities
        )
      );
      actualizarAyudas();
      handleStopTemporizador();
      HabitarBotones50Porcento();
      await delay(1500);
      setScreengameBien(false);
      setScreengameMal(false);
      setScreengame(true);
      handleReniciarTemporizador();
    }
  };

  const handleResponse = async (
    data: DataGame,
    partida: PartidaState,
    logros: any
  ) => {
    try {
      let isCorrect: boolean = false;

      if (partida.tipo === questionTypesEnum.Photo) {
        setRespuestaCorrecta(partida?.response?.F);
      } else if (partida.tipo === questionTypesEnum.GPT) {
        setRespuestaCorrecta(partida?.response?.F);
      } else {
        setRespuestaCorrecta(partida?.response?.N);
      }
      if (
        (data?.N !== partida.response?.N &&
          partida.tipo === questionTypesEnum.Classic) ||
        (partida.tipo === questionTypesEnum.Photo &&
          partida.response?.F !== data?.N) ||
        (partida.tipo === questionTypesEnum.GPT &&
          partida.response?.N !== data?.N)
      ) {
        if (activaAyuda2Oportunidades) {
          if (partida.tipo === questionTypesEnum.Photo) {
            dispatch(
              appActions.showToast({
                message: t("it_is_not", {
                  name: data.F,
                }),
                type: "danger",
                position: "top",
                icon: "globe",
              })
            );
          } else {
            dispatch(
              appActions.showToast({
                message: t("it_is_not", {
                  name: data?.N,
                }),
                type: "danger",
                position: "top",
                icon: "globe",
              })
            );
          }
          setActivaAyuda2Oportunidades(false);
          return;
        }
        setRespuestaIncorrecta(data.N + "");

        if (ayudaBotonConservarPuntos) {
          ayudaConservarPuntosHander();
          if (partida.point >= 30) {
            dispatch(
              partidaActions.erroresQuestionAyuda(
                retornarValorTemporizador(),
                partida.point,
                partida.errores,
                partida.tiempoControl,
                100 - retornarValorTemporizador()
              )
            );
            dispatch(
              appActions.showToast({
                message: t("help_not_to_lose_points_activated", {
                  name: data?.F,
                }),
                type: "danger",
                position: "bottom",
                icon: "globe",
              })
            );
          }
        } else {
          dispatch(
            partidaActions.erroresQuestion(
              retornarValorTemporizador(),
              partida.point,
              partida.errores,
              partida.tiempoControl,
              100 - retornarValorTemporizador()
            )
          );
        }
        authUser.user.DatosExtendido.Vida--;
        setScreengame(false);
        sonidoError();
        if (authUser.user.DatosExtendido.Vida > 0) {
          if (partida.tipo === questionTypesEnum.Photo) {
            dispatch(
              appActions.showToast({
                message: t("lost_life", {
                  name: authUser.user.Nick,
                  correcta: partida?.response?.F,
                }),
                type: "danger",
                position: "top",
                icon: "globe",
              })
            );
          } else if (partida.tipo === questionTypesEnum.GPT) {
            dispatch(
              appActions.showToast({
                message: t("lost_life", {
                  name: authUser.user.Nick,
                  correcta: partida?.response?.F,
                }),
                type: "danger",
                position: "top",
                icon: "globe",
              })
            );
          } else {
            dispatch(
              appActions.showToast({
                message: t("lost_life", {
                  name: authUser.user.Nick,
                  correcta: partida?.response?.N,
                }),
                type: "danger",
                position: "top",
                icon: "globe",
              })
            );
          }
          setScreengameMal(true);
          if (authUser.user.Auth) {
            gameService.QuitarAyuda(Producto.COMPRAR_VIDA);
          }
        } else {
          setScreengameMal(true);
          dispatch(gameActions.PARAR_JUEGO());
          clearInterval(intervalRef.current);
          handleStopTemporizador();
          await delay(2000);
          setScreengameMal(false);
          setScreengameSemVida(true);
          dispatch(
            appActions.showToast({
              message: t("lost_life_game_over", {
                name: authUser.user.Nick,
              }),
              type: "danger",
              position: "top",
              icon: "globe",
            })
          );
         
          await delay(1000);
          //poner el redicionamiento aqui
          gestionarGuardarPartida(partida, game, true);

          /*  if (!datosGuardados) {
            datosGuardados = true;
            setNum(1);
            gestionarGuardarPartida(partida, game, true);
          } */
          return;
        }


        if (partida.currentQuestion >= game.numberOfQuestions - 1) {
          gestionarGuardarPartida(partida, game, true);
          return;
        }
      } else {


        refValuePartida.current = partida;
        refValueGame.current = game;
        refValueAuthUser.current = authUser;

        isCorrect = true;
        GuardarPartida(partida);
        dispatch(
          partidaActions.aciertoQuestion(
            retornarValorTemporizador(),
            partida.point,
            partida.aciertos,
            partida.tiempoControl,
            100 - retornarValorTemporizador()
          )
        );
        setScreengameBien(true);
        setScreengame(false);

        dispatch(
          appActions.showToast({
            message: t("correct_answer", {
              response: data.T === "photo" ? data.F : data.N,
            }),
            type: "success",
            position: "top",
            icon: "globe",
            duration: 600,
          })
        );
      }

      const ganaLogro = VerificarSiTocaLogro(
        data,
        partida,
        logros,
        retornarValorTemporizador(),
        posicion,
        authUser
      );

      if (ganaLogro !== null && ganaLogro !== undefined) {
        if (!logros?.logro?.find((x) => x.LogroId === ganaLogro.LogroId)?.TieneLogro) {
          dispatch(appActions.showLogro(ganaLogro.LogroId));
        }
        handleStopTemporizador();
        setScreengameBien(true);
        setScreengameMal(false);
        setScreengame(false);
        setScreengameNivel(false);
        setLogroModal(true);
        return;
      }

      //Si estamos en la última pregunta, finalizar juego
      if (partida.currentQuestion >= game.numberOfQuestions) {
        gestionarGuardarPartida(partida, game, true);
        return;
      }
      //Cambio de nivel - hay otro sitio igual, refactorizar
      else if (verificarCambioNivel(partida, isCorrect)) {
        CambioDeNivel(
          posicionRanking,
          partida,
          dispatch,
          setScreengameBien,
          setScreengameMal,
          setScreengame,
          setScreengameNivel,
          ganoLogro,
          setLogroModal
        );
        setScreengameBien(false);
        setScreengameNivel(true);
      } else {
        //Resto de casos al responder
        if (isCorrect) {
          sonidoCorrecto();
        }

        dispatch(
          partidaActions.proximaPregunta(
            partida.answered,
            partida.currentQuestion,
            game.cities,
            game.provinceCities
          )
        );
        actualizarAyudas();
        handleStopTemporizador();
        HabitarBotones50Porcento();
        //setActivaAyuda2Oportunidades(false);
        //setactivaAyuda2NoPerderPuntosSiFalla(false);
        //await delay(2000);
        setScreengameBien(false);
        setScreengameMal(false);
        setScreengame(true);
        handleReniciarTemporizador();
      }
    } catch (error) {
      gestionarGuardarPartida(partida, game, true);
    }
  };

  const handleModal = () => {
    // setNum((prev) => prev - 0.5);
    handleQuitarTempoPorAquiWikipedia();
    setShowModal(true);
  };

  useIonViewDidEnter(() => {});

  /*
  const TimeGame = () => {
    dispatch(gameActions.timeRun(partida.time));
    TimeGame();
  };
*/
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="menu_movil_home_2">
          {MenuApp(present, t)}
          <IonTitle>{t("name_app")}</IonTitle>
          <IonButtons slot="start">
            <IonBackButton defaultHref="dashboard" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRow>
          <IonCol
            size="12"
            size-md="9"
            className={
              styles.centro_pagina_quiz +
              " " +
              (screengameBien === true ? styles.bien : "") +
              " " +
              (screengameMal === true || screengameSemVida === true
                ? styles.mal
                : "")
            }
          >
            <div className={screengame === true ? styles.show : styles.hide}>
              <Timer
                dispatch={dispatch}
                actualizacion={actualizarACada}
                ref={timerRef}
              ></Timer>

              <div className={styles.box_question}>
                <ComponentePregunta />
              </div>

              <div className={styles.caja_respuesta}>
                {partida?.quiz?.length > 0
                  ? partida?.quiz?.map((data, key) => {
                      return (
                        <IonButton
                          shape="round"
                          size="large"
                          disabled={deshabilitarBoton(
                            key,
                            respuesta1,
                            respuesta2,
                            respuesta3,
                            respuesta4
                          )}
                          className={styles.respuesta}
                          onClick={() => handleResponse(data, partida, logros)}
                          key={key}
                        >
                          {data.N == null ? data : data?.N}
                        </IonButton>
                      );
                    })
                  : ""}
              </div>

              <div className={styles.caja_ayuda}>
                {ayudaBoton50 && (
                  <div className={styles.contenedorAyuda}>
                    <img
                      alt={t("help_50")}
                      src="/assets/icon/ayuda50.png"
                      onClick={() => ayuda50Hander(partida)}
                    ></img>
                    <IonBadge className={styles.cantidadAyuda} color="success">
                      {authUser?.user?.DatosExtendido?.Ayuda50}
                    </IonBadge>
                  </div>
                )}
                {/* <img
                  className={
                    partida.tipo === questionTypesEnum.Photo
                      ? styles.showInline
                      : styles.hide
                  }
                    src="/assets/icon/wikifoto.png"
                    onClick={handleModal}
                  ></img> */}

                {ayudaBotonMasTiempo && (
                  <div className={styles.contenedorAyuda}>
                    <img
                      alt={t("help_longer")}
                      src="/assets/icon/ayudaMasTiempo.png"
                      onClick={masTiempoHander}
                    ></img>
                    <IonBadge className={styles.cantidadAyuda} color="success">
                      {authUser?.user?.DatosExtendido?.AyudaMasTiempo}
                    </IonBadge>
                  </div>
                )}

                {ayudaBotonPreguntaCorrecta && (
                  <div className={styles.contenedorAyuda}>
                    <img
                      alt={t("help_correct_answer")}
                      src="/assets/icon/ayudaRespuestaCorrecta.png"
                      onClick={ayudaRespuestaCorrectaHander}
                    ></img>
                    <IonBadge className={styles.cantidadAyuda} color="success">
                      {authUser?.user?.DatosExtendido?.AyudaPreguntaCorrecta}
                    </IonBadge>
                  </div>
                )}

                {ayudaBotonContestar2Veces && (
                  <div className={styles.contenedorAyuda}>
                    <img
                      alt={t("help_doble")}
                      src="/assets/icon/oportunidadDoble.png"
                      onClick={ayudaBotonContestar2VeceHander}
                    ></img>
                    <IonBadge className={styles.cantidadAyuda} color="success">
                      {authUser?.user?.DatosExtendido?.AyudaContestar2Veces}
                    </IonBadge>
                  </div>
                )}

                <img
                  alt="Wikipedia"
                  src="/assets/icon/wikifoto.png"
                  onClick={handleModal}
                ></img>

                <div className={styles.contenedorAyuda}>
                  <img alt={t("life")} src="/assets/icon/vida.png"></img>
                  <IonBadge className={styles.cantidadAyuda} color="success">
                    {authUser?.user?.DatosExtendido?.Vida}
                  </IonBadge>
                </div>

                {ayudaBotonConservarPuntos && (
                  <div className={styles.contenedorAyuda}>
                    <img
                      alt={t("help_not_to_lose_points")}
                      src="/assets/icon/ayudaRecuperarPuntos.png"
                      // onClick={ayudaConservarPuntosHander}
                    ></img>
                    <IonBadge className={styles.cantidadAyuda} color="success">
                      {authUser?.user?.DatosExtendido?.RecuperarPuntosPerdido}
                    </IonBadge>
                  </div>
                )}
              </div>
            </div>
            <div
              className={screengameBien === true ? styles.show : styles.hide}
            >
              <div className={styles.container_center_item}>
                <CorrectoLottie />
              </div>
              <h2 className={styles.respuestaCorrecta}>
                {t("correct_answer", { response: respuestaCorrecta })}
              </h2>
              <div className={styles.caja_botonV2}>
                <IonButton
                  color="success"
                  shape="round"
                  size="large"
                  className={styles.botonContinuar}
                  onClick={cambioNivelContinuar}
                >
                  <IonIcon slot="start" icon={play} />
                  {t("continue")}
                </IonButton>
              </div>
            </div>

            <div
              className={screengameSemVida === true ? styles.show : styles.hide}
            >
              <div className={styles.container_center_item}>
                <h2 className="texto_titulo_efecto fontSize2">
                  {t("game_over")}
                </h2>
              </div>

              <div className={styles.container_center_item}>
                <Corazon />
              </div>
              <div className={styles.container_center_item}>
                <h2 className="fontType2">{t("lost_life_game_over_screen")}</h2>
              </div>
            </div>

            <div className={screengameMal === true ? styles.show : styles.hide}>
              <h1 className={styles.respuestaIncorrecta}>
                {t("wrong_answer", { response: respuestaIncorrecta })}
              </h1>
              <div className={styles.container_center_item}>
                <ErrorLottie />
              </div>
              <h2 className={styles.respuestaCorrecta}>
                {t("the_correct_answer_was", { response: respuestaCorrecta })}
              </h2>
            </div>

            <div
              className={screengameNivel === true ? styles.show : styles.hide}
            >
              <div className={styles.container_center_item}>
                <NivelLottie />
              </div>

              <div className={styles.newlevel}>
                <img
                  src="/assets/icon/level.png"
                  alt="level"
                  height={60}
                  width={60}
                ></img>{" "}
                <span className={styles.levelChange}>
                  {" "}
                  {t("level")} {partida.level}
                </span>
                {posicion > 0 ? (
                  <p>{t("ranking_position", { posicion: posicion })}</p>
                ) : (
                  ""
                )}
              </div>

              <div className={styles.caja_boton}>

                <IonButton
                  color="success"
                  shape="round"
                  size="large"
                  className={styles.botonContinuar}
                  onClick={cambioNivelContinuar}
                >
                  <IonIcon slot="start" icon={play} />
                  {t("continue")}
                </IonButton>
              </div>
            </div>
          </IonCol>

          <IonCol size="12" size-md="3" className="hide_pc">
          <Media
              query="(min-width: 600px)"
              render={() => (
                <BannerLocalLateralGrande></BannerLocalLateralGrande>
              )}
            />
          </IonCol>
        </IonRow>

        <IonModal isOpen={showModal} cssClass="fullscreen">
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="end">
                <IonButton onClick={() => setShowModal(false)}>
                  {" "}
                  {t("close")}
                </IonButton>
              </IonButtons>
              <IonTitle>{t("web")}</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <iframe
              title="Web"
              id="mymodal"
              src={
                partida?.response?.W === null
                  ? partida?.response?.V?.replace("http:", "https://")
                  : partida?.response?.W?.replace("http:", "https://")
              }
              className={styles.iframeStyle}
            ></iframe>
          </IonContent>
        </IonModal>

        {authUser !== null && authUser.isAuth && miLogro !== null ? (
          <GanaLogro
            estadoModal={logroModal}
            eventoCerrar={cerrarModaGanalLogro}
            logroId={miLogro === null ? 1 : miLogro?.LogroId}
          ></GanaLogro>
        ) : (
          ""
        )}
      </IonContent>
    </IonPage>
  );
};

export default Quiz;


